.Jz-input-label {
    font-size: 0.8rem;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 5px;
}

.Jz-input-container {
    display: flex;
    flex-direction: column;
}