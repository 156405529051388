.Jz-input {
    background-color: transparent;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
    outline: none;
    color: #FFF;
    padding: 5px;
    border: none;
    border-bottom: 1px solid #AAA;
    min-width: 15rem;
}

.Jz-input:focus {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-bottom: 1px solid #FFF;
}