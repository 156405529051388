.Jz-btn {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid transparent;
    color: #FFF;
    cursor: pointer;
}
.Jz-btn-sm {
    padding: 5px;
}
.Jz-btn:hover {
    border: 1px solid #AAA;
}
.Jz-btn-primary {
    background-color: rgb(62, 76, 202);
}
.Jz-btn-primary:active {
    background-color: rgb(45, 55, 145);
}
.Jz-btn-primary:disabled {
    background-color: rgb(134, 134, 134);
}